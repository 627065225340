body {
  overflow-x: hidden;
}

.App {
  overflow-x: hidden;
  width: 100vw;
  color: rgb(51,51,51);
  position: relative;
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  height: 276px;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-banner {
  position: absolute;
  left: 70%;
  height: 276px;
  width: 1500px;
  margin-left: -1060px;
}

.title-text {
  position: absolute;
  width: 100vw;
}

h1 {
  font-family: 'josefin slab', serif;
  font-size: 88px;
  margin: 50px;
  display: inline-block;
}

h3 {
  font-family: 'oswald', sans-serif;
  font-size: 27px;
  font-weight: 200;
  line-height: 1.3em;
  margin: 50px;
  margin-top: 100px;
  float: right;
  text-align: right;
}

h4 {
  font-family: Brandon_light, sans-serif;
  font-size: 41px;
  font-weight: 200;
  line-height: 1.4em;
  margin-bottom: -10px;
}

h5 {
  font-family: normal, helvetica, sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

h6 {
  font-family: normal, helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 25px;
}

.media-name {
  padding-top: 15px;
  padding-bottom: 10px;
}

.media-container {
  padding: 20px;
  padding-bottom: 5px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

p {
  font-family: helvetica, sans-serif;
  font-size: 18px;
  font-weight: 100;
  line-height: 1.9em;
  text-align: justify;
  margin-bottom: 40px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  width: 100%;
  height: 40px;
  border-top: 1px solid rgb(232,230,230);;
  cursor: pointer;
}

#track0 {
  border: none;
}

.popin {
  position: absolute;
  margin-left: -10px;
  margin-top: -5px;
  width: 30px;
  opacity: 0;
  color: rgb(0,87,225);
}

.right-column {
  float: right;
  width: 50vw;
}

.photo {
  padding: 10%;
  padding-bottom: 0;
  width: 80%;
}

.left-column {
  display: inline-block;
  width: 40vw;
  padding-left: 5vw;
}

.track-list {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2em;
  font-family: normal, helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.num {
  width: 10%;
}

.name {
  width: 75%;
}

.time {
  width: 15%;
  text-align: right;
}

.footerCopy {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 50px;
}

li:active {
  color: rgb(0,87,225);
}

li:active > .num > .popin {
  opacity: 1;
}

@media (hover:hover) {
  li:hover {
    color: rgb(0,87,225);
  }
  
  li:hover > .num > .popin {
    opacity: 1;
  }
}

@media (max-width: 385px){
  .name {
    width: 65%;
  }

  .time {
    width: 25%;
  }
}

@media (max-width: 860px){
  .App-banner {
    margin-left: -800px;
  }

  h1 {
    font-size: 66px;
    margin: 35px;
    margin-left: 25px;
    margin-bottom: 0;
    line-height: 1.2em;
  }

  h3 {
    font-size: 20px;
    margin: 35px;
    margin-top: 20px;
    margin-left: 25px;
    float: none;
    text-align: left;
    bottom: 100%;
  }

  h4 {
    text-align: center;
    margin-top: -10px;
    line-height: 1;
  }

  .right-column {
    float: none;
    width: 100vw;
  }

  .photo {
    padding-bottom: 5%;
  }

  .left-column {
    width: 80vw;
    padding-left: 10vw;
  }
}

@media (min-width: 1460px) {
  .App-banner {
    left: 0;
    position: absolute;
    width: 100%;
    height: 19.2vw;
    margin-left: 0;
  }
  
  .App-header {
    height: 19.2vw;
  }
  
  .App-header h1 {
    margin-top: 80px;
  }

  h3 {
    font-size: 50px;
  }
}
